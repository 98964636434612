body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.heatmap{
  text-align: left;
  position: relative;
  position: relative;
  height: 540px;
  width: 840px;
  transform-origin: top left;
  /* overflow: auto; */
}
.heatmapExample{
  position: relative;
}
/* .heatmap .heatmap-canvas{
  z-index: 0!important;
}
.heatmap.moveMap-active .heatmap-canvas{
  z-index: 100!important;
} */

.heatmap.moveMap-active:hover .upper-canvas {
  cursor: grab!important;
}
.heatmap.moveMap-grabbing:hover .upper-canvas  {
  cursor: grabbing!important;

}

.canvas-container{
  width: 300px;
    height: 150px;
    position: absolute!important;
    top: 0;
    left: 0;
    /* width: 840px!important;
    height: 540px!important; */
    z-index: 100;
    transform-origin: top left;
}
.button-active{
  color: #fff;
  background: green;
}
button:hover{
  cursor: pointer;
}

.options-wrapper{
  width: 25%;
    position: fixed;
    top: 15px;
    right: 15px;
}
